export const EVENTCODE = {
  AA_JOURNEY_SELECTED: 'AA_JOURNEY_SELECTED',
  PDF_JOURNEY_SELECTED: 'PDF_JOURNEY_SELECTED',
  PDF_JOURNEY_GO_BACK: 'PDF_JOURNEY_GO_BACK',
  NETBANKING_JOURNEY_SELECTED: 'NETBANKING_JOURNEY_SELECTED',
  NETBANKING_JOURNEY_GO_BACK: 'NETBANKING_JOURNEY_GO_BACK',
  JOURNEY_COMPLETED: 'JOURNEY_COMPLETED',
  JOURNEY_FAILED: 'JOURNEY_FAILED',
  FIP_SELECTED: 'FIP_SELECTED',
  AA_FAILED: 'AA_FAILED',
  PDF_FAILED: 'PDF_FAILED',
  NB_FAILED: 'NB_FAILED',
  PDF_SELETED_FALLBACK: 'PDF_SELECTED_AS_FALLBACK',
  NB_SELECTED_FALLBACK: 'NB_SELECTED_AS_FALLBACK',
};

export const logoBaseUrl = 'https://logo.ignosis.ai/';
export const topBanks = [
  'FIP-ID',
  'sbi-fip-uat',
  'HDFC-FIP',
  'ICICI-FIP',
  'AXIS001',
  'BARBFIP_UAT',
  'BARBFIP',
  'KotakMahindraBank-FIP',
  'BOI-FIP',
  'UBI-FIP',
  'IDFCFirstBank-FIP',
  'IDFC-FIP',
  'BOI-UAT-FIP',
  'sbi-fip',
];
export const topBankPriority: any = {
  BARBFIP_UAT: 1,
  BARBFIP: 1,
  'sbi-fip-uat': 2,
  'sbi-fip': 2,
  'HDFC-FIP': 3,
  'UBI-FIP': 4,
  'ICICI-FIP': 5,
  AXIS001: 6,
  'IDFCFirstBank-FIP': 7,
  'IDFC-FIP': 7,
  'BOI-FIP': 8,
  'BOI-UAT-FIP': 8,
  'FIP-ID': 9,
  'KotakMahindraBank-FIP': 9,
};
export const baseUrl = process.env.REACT_APP_BASE_URL;
export const urlShortenerBaseUrl = process.env.REACT_APP_URL_SHORTENER_URL;
export const prefix = process.env.REACT_APP_BASE_PATH;

export const AATERMINALEVENTS = {
  FAILURE: [
    'IFRAME_FAILED',
    'NO_ACCOUNTS_FOUND',
    'CONSENT_REJECTED_SUCCESS',
    'CONSENT_APPROVED_FAILED',
    'CONSENT_REJECTED_FAILED',
    'CONSENT_NOT_IN_PENDING_STATE',
  ],
  SUCCESS: ['CONSENT_APPROVED_SUCCESS'],
};

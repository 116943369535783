import { SESSIONOBJECT } from '../App';
import { EVENTCODE } from '../Constants/constants';
let eventCounter = 1;

export const pushEventToURLShortener = async (
  eventCode: string,
  error?: string
) => {
  await fetch(process.env.REACT_APP_URL_SHORTENER_URL + 'api/event', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      API_KEY: process.env.REACT_APP_URL_SHORTENER_API_KEY!,
    },
    body: JSON.stringify({
      trackingId: SESSIONOBJECT.TRACKINGID,
      consentHandle: SESSIONOBJECT.CONSENTHANDLES,
      eventCode: eventCode,
      eventCounter: eventCounter++,
      errorMessage: error ? error : null,
      accountAggregator: 'NONAA',
      fiuId: SESSIONOBJECT.FIUID,
      fipId: SESSIONOBJECT.FIP_ID ? [SESSIONOBJECT.FIP_ID] : null,
      timeStamp: new Date().toUTCString(),
    }),
  }).catch((e) => {
    //Catches event
  });
};

const pushEventToReactNativeParent = (eventCode: string, error?: string) => {
  pushEventToURLShortener(eventCode, error);

  if ((window as any)?.ReactNativeWebView) {
    (window as any)?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        eventCode: eventCode,
        error: error,
      })
    );
  } else {
    window.parent.postMessage({ eventCode: eventCode, error: error }, '*');
  }
};
export const pushJourneyCompletedEvent = (e?: string) => {
  pushEventToReactNativeParent(EVENTCODE.JOURNEY_COMPLETED, e);
};
export const pushJourneyFailedEvent = (e?: string) => {
  pushEventToReactNativeParent(EVENTCODE.JOURNEY_FAILED, e);
};
export const pushAAJourneySelected = () => {
  pushEventToReactNativeParent(EVENTCODE.AA_JOURNEY_SELECTED);
};
export const pushNetBankingJourneySelected = (isFallback: boolean) => {
  if (isFallback) {
    pushEventToReactNativeParent(EVENTCODE.NB_SELECTED_FALLBACK);
  } else {
    pushEventToReactNativeParent(EVENTCODE.NETBANKING_JOURNEY_SELECTED);
  }
};
export const pushNetBankingGoBack = () => {
  pushEventToReactNativeParent(EVENTCODE.NETBANKING_JOURNEY_GO_BACK);
};
export const pushPDFJourneySelected = (isFallback: boolean) => {
  if (isFallback) {
    pushEventToReactNativeParent(EVENTCODE.PDF_SELETED_FALLBACK);
  } else {
    pushEventToReactNativeParent(EVENTCODE.PDF_JOURNEY_SELECTED);
  }
};
export const pushPDFGoBack = () => {
  pushEventToReactNativeParent(EVENTCODE.PDF_JOURNEY_GO_BACK);
};
export const pushFipSelected = () => {
  pushEventToReactNativeParent(EVENTCODE.FIP_SELECTED);
};
export const pushAAFailed = () => {
  pushEventToReactNativeParent(EVENTCODE.AA_FAILED);
};
export const pushPDFFailed = () => {
  pushEventToReactNativeParent(EVENTCODE.PDF_FAILED);
};
export const pushNBFailed = () => {
  pushEventToReactNativeParent(EVENTCODE.NB_FAILED);
};

import { SESSIONOBJECT } from '../App';
import { baseUrl, urlShortenerBaseUrl } from '../Contants';
import { IniateConsentRequest } from '../Types/RequestTypes';

export const getMetaData = () => {
  return fetch(
    urlShortenerBaseUrl +
      `api/url/details/${SESSIONOBJECT.TRACKINGID}?journeyType=PFM`,
    {
      method: 'GET',
      headers: {
        API_KEY: process.env.REACT_APP_URL_SHORTENER_API_KEY!,
      },
    }
  );
};

export const getBankList = () => {
  return fetch(baseUrl + `${SESSIONOBJECT.TENANTID}/web/fip`, {
    method: 'GET',
    headers: {
      API_KEY: SESSIONOBJECT.API_KEY!,
    },
  });
};
export const getBankDetails = () => {
  return fetch(
    baseUrl + `${SESSIONOBJECT.TENANTID}/web/fip/${SESSIONOBJECT.FIP_ID}`,
    {
      method: 'GET',
      headers: {
        API_KEY: SESSIONOBJECT.API_KEY!,
      },
    }
  );
};

export const initiateConsent = (body: IniateConsentRequest) => {
  return fetch(baseUrl + `${SESSIONOBJECT.TENANTID}/web/initiate/consents`, {
    method: 'POST',
    headers: {
      API_KEY: SESSIONOBJECT.API_KEY!,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
};
